import styled, { css } from 'styled-components'
import { images } from '../../styles/images'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`
export const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
    height: 858px;
    margin-top: 80px;
    background-size: 100% 100%;
    background-image: url(${images.main1});
    background-repeat: no-repeat;
    @media (max-width: 1024px) {
        height: 680px;
    }
    @media (max-width: 575px) {
        background-size: cover;
        background-position: center;
    }
`
export const Title = styled.h1`
    max-width: 65%;
    margin: 0;
    font-family: 'CormorantBold';
    font-size: 104px;
    line-height: 100px;
    color: #ffffff;
    text-align: center;
    @media (max-width: 1024px) {
        max-width: 400px;
        font-size: 50px;
        line-height: 50px;
    }
`

export const WrapAdvocat = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: radial-gradient(
        76.19% 57.11% at 50% 50%,
        #666867 0%,
        #57595b 83.06%,
        #4b4c50 100%
    );
    @media (max-width: 575px) {
        align-items: start;
        flex-direction: column-reverse;
    }
`
export const ImgMen = styled.img`
    width: 50%;
    @media (max-width: 575px) {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: scale(-1, 1);
    }
`

export const WrapTextAd = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 45%;
    margin-right: 80px;
    @media (max-width: 768px) {
        gap: 20px;
        margin-right: 30px;
    }
    @media (max-width: 575px) {
        width: 80%;
        margin-right: 0px;
        padding: 40px 0 180px 20px;
    }
`
export const TitleMen = styled.div`
    font-family: 'CormorantBold';
    font-size: 56px;
    line-height: 50px;
    color: #ffffff;
    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 40px;
    }
    @media (max-width: 768px) {
        font-size: 30px;
        line-height: 30px;
    }
`
export const TextMen = styled.div`
    font-family: 'CormorantRegilar';
    font-size: 24px;
    color: #ffffff;
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
    }
    @media (max-width: 575px) {
        width: 83%;
        font-size: 18px;
    }
`
export const WrapCarousel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 800px;
    background: #ffffff;
    @media (max-width: 1024px) {
        height: 600px;
    }
    @media (max-width: 768px) {
        height: 400px;
    }
    @media (max-width: 575px) {
        align-items: center;
    }
`
export const Services = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 80px;
    @media (max-width: 768px) {
        padding-left: 30px;
    }
`
export const ImgServices = styled.div`
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 660px;
    height: 800px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-in-out;
    ${(props) =>
        props.hovered &&
        css`
            background-image: url(${props.img});
        `}
    @media (max-width: 1024px) {
        width: 500px;
        height: 600px;
    }
    @media (max-width: 768px) {
        width: 350px;
        height: 400px;
    }
    @media (max-width: 575px) {
        display: none;
    }
`
export const NameService = styled.div`
    max-width: 500px;
    width: 50%;
    padding-top: 40px;
    font-family: 'CormorantBold';
    font-size: 40px;
    line-height: 40px;
    color: rgba(224, 224, 224, 1);
    cursor: default;
    :hover {
        color: #000000;
    }
    @media (max-width: 1024px) {
        padding-top: 20px;
        font-size: 30px;
        line-height: 30px;
    }
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
    }
    @media (max-width: 575px) {
        width: 100%;
        padding-bottom: 5px;
        font-family: 'CormorantRegilar';
        font-size: 24px;
        line-height: 28px;
        color: rgba(45, 50, 56, 1);
        text-align: center;
        border-bottom: 1px solid rgba(239, 239, 239, 1);
    }
`

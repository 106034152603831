import logo from '../images/logo.svg'
import divorce from '../images/divorce.png'
import gavel from '../images/gavel.png'
import glasses from '../images/glasses.png'
import law from '../images/law.png'
import main1 from '../images/main1.png'
import men from '../images/men.png'
import notary from '../images/notary.png'
import seal from '../images/seal.png'
import letter from '../images/letter.svg'
import point from '../images/point.svg'
import footer_logo from '../images/footer_logo.svg'
import arrow_down from '../images/arrow.png'
import notaries from '../images/notaries.png'
import download from '../images/download.svg'
import fog from '../images/fog.png'
import partner from '../images/partner.png'
import hugeBtn from '../images/huge-btn.png'
import contact from '../images/contact.png'
import contact_bg from '../images/contact_bg.png'
import town from '../images/town.png'
import divorce_bg from '../images/divorce_bg.png'
import rings from '../images/rings.png'
import spouses from '../images/spouses.png'
import sign from '../images/sign.png'
import division_bg from '../images/division_bg.png'
import division1 from '../images/division1.png'
import division2 from '../images/division2.png'
import realEstate from '../images/realEstate.png'
import estate from '../images/estate.png'
import estate1 from '../images/estate1.png'
import notaryServices from '../images/notaryServices.png'
import service1 from '../images/service1.png'
import service2 from '../images/service2.png'
import service3 from '../images/service3.png'
import service4 from '../images/service4.png'
import criminal from '../images/criminal_bg.png'
import criminal1 from '../images/criminal1.png'
import hugeBtnMobile from '../images/hugeBtnMobile.png'

export const images = {
    logo: logo,
    divorce: divorce,
    gavel: gavel,
    glasses: glasses,
    law: law,
    main1: main1,
    men: men,
    notary: notary,
    seal: seal,
    letter: letter,
    point: point,
    footer_logo: footer_logo,
    arrow_down: arrow_down,
    notaries: notaries,
    download: download,
    fog: fog,
    partner: partner,
    hugeBtn: hugeBtn,
    contact: contact,
    contact_bg: contact_bg,
    town: town,
    divorce_bg: divorce_bg,
    rings: rings,
    spouses: spouses,
    sign: sign,
    division_bg: division_bg,
    division1: division1,
    division2: division2,
    realEstate: realEstate,
    estate: estate,
    estate1: estate1,
    notaryServices: notaryServices,
    service1: service1,
    service2: service2,
    service3: service3,
    service4: service4,
    criminal: criminal,
    criminal1: criminal1,
    hugeBtnMobile: hugeBtnMobile,
}

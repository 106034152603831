import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { PATH } from '../../utils/routes'
import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { ButtonStart } from '../../components/navbar/Navbar.styles'
import { images } from '../../styles/images'
import {
    Container,
    Wrap,
    Title,
    WrapAdvocat,
    WrapTextAd,
    ImgMen,
    TitleMen,
    TextMen,
    WrapCarousel,
    Services,
    NameService,
    ImgServices,
} from './MainPage.styles'

export const MainPage = () => {
    const services = [
        { name: 'Divorce proceedings', img: images.divorce },
        { name: 'Division of Property in Divorce', img: images.gavel },
        { name: 'Criminal cases', img: images.seal },
        {
            name: 'Real Estate and Construction Law in Comoros',
            img: images.law,
        },
        { name: 'Real Estate', img: images.glasses },
        { name: 'Notary services', img: images.notary },
    ]
    const [hoveredIndex, setHoveredIndex] = useState(0)

    const handleMouseEnter = (index) => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndex(0)
    }
    return (
        <Container>
            <Wrap>
                <Title>Your Trusted Partner in Legal Matters</Title>
                <NavLink to={PATH.CONTACT}>
                    <ButtonStart props="props">Contact Us</ButtonStart>
                </NavLink>
            </Wrap>
            <WrapAdvocat>
                <ImgMen src={images.men} alt={''} />
                <WrapTextAd>
                    <TitleMen>
                        CABINET D`AVOCATS MOHAMED & C is a law firm located in
                        Fomboni, Mwali, Comoros.
                    </TitleMen>
                    <TextMen>
                        Founded in 2003 by MOHAMED BOINA, a distinguished legal
                        professional with a solid academic background from the
                        University of Comoros in the field of business Law, our
                        firm boasts a decade of expertise as a reputable notary.
                        Committed to upholding the highest standards of legal
                        practice, CABINET D`AVOCATS MOHAMED & C Law Law Firm is
                        your go-to destination for comprehensive legal solutions
                        tailored to the unique needs of businesses in Comoros.
                    </TextMen>
                </WrapTextAd>
            </WrapAdvocat>
            <WrapCarousel>
                {services.map((service, index) => (
                    <Services key={index}>
                        <NameService
                            img={service.img}
                            hovered={hoveredIndex === index}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {service.name}
                        </NameService>
                        <ImgServices
                            img={service.img}
                            hovered={hoveredIndex === index}
                        ></ImgServices>
                    </Services>
                ))}
            </WrapCarousel>
            <ContactForm />
        </Container>
    )
}

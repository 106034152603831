import styled from 'styled-components'
import { images } from '../../styles/images'
import { ThirdBackground } from '../partners/Partners.styles'

export const ThirdBackground1 = styled(ThirdBackground)`
    background-image: url(${images.realEstate});
    @media (max-width: 575px) {
        height: 550px;
    }
`

import { NavLink } from 'react-router-dom'
import { PATH } from '../../utils/routes'
import { images } from '../../styles/images'
import { DropDownWrapper, Link, DropDownContent } from './DropDown.styles'
import { Title } from '../navbar/Navbar.styles'

export const DropDown = (props) => {
    return (
        <DropDownWrapper>
            <Link onClick={() => props.toggleDropdown()}>
                What we do
                <img
                    src={images.arrow_down}
                    alt={''}
                    style={{ marginLeft: '7px' }}
                />
            </Link>

            {!props.showDropDown && (
                <DropDownContent showDropDown={props.showDropDown}>
                    <Title
                        props="props"
                        onClick={() => {
                            props.changeOpenBurger()
                            props.toggleDropdown()
                        }}
                    >
                        <NavLink to={PATH.DIVORCE}>Divorce proceedings</NavLink>
                    </Title>
                    <Title
                        props="props"
                        onClick={() => {
                            props.changeOpenBurger()
                            props.toggleDropdown()
                        }}
                    >
                        <NavLink to={PATH.DIVISIONPROPERTY}>
                            Division of Property in Divorce
                        </NavLink>
                    </Title>
                    <Title
                        props="props"
                        onClick={() => {
                            props.changeOpenBurger()
                            props.toggleDropdown()
                        }}
                    >
                        <NavLink to={PATH.CONSTRUCTIONLAW}>
                            Real Estate and Construction Law in Comoros
                        </NavLink>
                    </Title>
                    <Title
                        props="props"
                        onClick={() => {
                            props.changeOpenBurger()
                            props.toggleDropdown()
                        }}
                    >
                        <NavLink to={PATH.REALESTATE}>Real Estate</NavLink>
                    </Title>
                    <Title
                        props="props"
                        onClick={() => {
                            props.changeOpenBurger()
                            props.toggleDropdown()
                        }}
                    >
                        <NavLink to={PATH.CRIMINAL}>Criminal casese</NavLink>
                    </Title>
                    <Title
                        props="props"
                        onClick={() => {
                            props.changeOpenBurger()
                            props.toggleDropdown()
                        }}
                    >
                        <NavLink to={PATH.NOTARYSERVICES}>
                            Notary services
                        </NavLink>
                    </Title>
                </DropDownContent>
            )}
        </DropDownWrapper>
    )
}

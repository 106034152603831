import { NavLink } from 'react-router-dom'
import { Title } from '../navbar/Navbar.styles'
import { PATH } from '../../utils/routes.js'
import { handlerScrollUp } from '../../helpers/ScrollTo'
import { images } from '../../styles/images'
import {
    Wrap,
    Logo,
    WrapLinks,
    WrapLinks2,
    WrapRight,
    CopyRight,
    TextCopyRight,
    Logo2,
} from './Footer.styles'

export const Footer = () => {
    return (
        <Wrap>
            <WrapLinks>
                <NavLink to={PATH.MAIN}>
                    <Logo2 src={images.footer_logo} />
                </NavLink>
                <Title>
                    <NavLink to={PATH.MAIN} onClick={handlerScrollUp}>
                        Home Page
                    </NavLink>
                </Title>
                <Title>
                    <NavLink to={PATH.NOTARIES} onClick={handlerScrollUp}>
                        Notaries & Service
                    </NavLink>
                </Title>
                <NavLink to={PATH.MAIN} onClick={handlerScrollUp}>
                    <Logo src={images.footer_logo} />
                </NavLink>
                <Title>
                    <NavLink to={PATH.PARTNERS} onClick={handlerScrollUp}>
                        Our partners
                    </NavLink>
                </Title>
                <Title>
                    <NavLink to={PATH.CONTACT} onClick={handlerScrollUp}>
                        Contact Us
                    </NavLink>
                </Title>
            </WrapLinks>
            <WrapLinks2>
                <Title>
                    <NavLink to={PATH.DIVORCE} onClick={handlerScrollUp}>
                        Divorce proceedings
                    </NavLink>
                </Title>
                <Title>
                    <NavLink
                        to={PATH.DIVISIONPROPERTY}
                        onClick={handlerScrollUp}
                    >
                        Division of Property in Divorce
                    </NavLink>
                </Title>
                <Title>
                    <NavLink
                        to={PATH.CONSTRUCTIONLAW}
                        onClick={handlerScrollUp}
                    >
                        Real Estate and Construction Law in Comoros
                    </NavLink>
                </Title>
                <Title>
                    <NavLink to={PATH.REALESTATE} onClick={handlerScrollUp}>
                        Real Estate
                    </NavLink>
                </Title>
                <Title>
                    <NavLink to={PATH.CRIMINAL} onClick={handlerScrollUp}>
                        Criminal cases
                    </NavLink>
                </Title>
                <Title>
                    <NavLink to={PATH.NOTARYSERVICES} onClick={handlerScrollUp}>
                        Notary services
                    </NavLink>
                </Title>
            </WrapLinks2>
            <WrapRight>
                <CopyRight>
                    <img src={images.letter} alt={'letter'} />
                    <TextCopyRight>support@cabadvocats.com</TextCopyRight>
                </CopyRight>
                <CopyRight>
                    <img src={images.point} alt={'point'} />
                    <TextCopyRight>
                        Boulevard Karthala, Darsanda, Bâtiment Ridjali, 1er
                        étage, Fomboni, Mwali, Comores
                    </TextCopyRight>
                </CopyRight>
            </WrapRight>
        </Wrap>
    )
}

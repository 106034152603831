import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container } from '../mainPage/MainPage.styles'
import {
    WrapMainImg,
    SecondBackground,
    Title,
    Text,
    WrapSection,
    TextColor,
    TextColor2,
} from '../partners/Partners.styles'
import {
    WrapBlocks,
    WrapBlock,
    Block2,
    TitleBlock,
    TextBlock,
} from '../notaries/Notaries.styles'
import {
    FirstBackground,
    ThirdBackground1,
    BlockImg1,
    BlockImg2,
    BlockImg3,
    BlockImg2Mob,
} from './Divorce.styles'

export const Divorce = () => {
    return (
        <Container>
            <WrapMainImg>
                <FirstBackground></FirstBackground>
                <SecondBackground>
                    <Title>Divorce proceedings</Title>
                    <Text props="props" style={{ textAlign: 'start' }}>
                        Marriage and the circumstances that terminate the
                        marriage are regulated in the Family (Marriage and
                        Divorce) Law No. 1/98 in Comoros and are one of the
                        situations that terminate the marriage.
                    </Text>
                    <Text>
                        Along with the substitution of the divorce case, legal
                        problems such as custody, alimony, property division,
                        and compensation, which are annexes to the divorce,
                        should also be resolved. Article 1 of Law No. 98/24
                        regulates the grounds for divorce.
                    </Text>
                </SecondBackground>
                <ThirdBackground1></ThirdBackground1>
            </WrapMainImg>
            <WrapSection>
                <TextColor>
                    Accordingly, the reasons for divorce are divided into three
                    as{' '}
                    <TextColor2>
                        (1) consensual divorce, (2) divorce due to the fact that
                        the marriage union has been shaken from its foundation,
                    </TextColor2>
                    and{' '}
                    <TextColor2>
                        (3) divorce depending on the separation of the spouses.
                    </TextColor2>
                </TextColor>
            </WrapSection>
            <WrapBlocks>
                <WrapBlock>
                    <Block2 props="props">
                        <TitleBlock>(1) Сonsensual divorce</TitleBlock>
                        <TextBlock>
                            Consensual divorce is regulated in the law as a
                            special case of shaking the foundation of the
                            marriage union. If the marriage has lasted for at
                            least one year, if the spouses apply together, or if
                            one spouse accepts the divorce case filed by the
                            other for any reason, the marriage union is deemed
                            to have been shaken to its foundation.
                        </TextBlock>
                    </Block2>
                    <BlockImg1></BlockImg1>
                </WrapBlock>
                <WrapBlock>
                    <BlockImg2></BlockImg2>
                    <Block2 props="props">
                        <TitleBlock>
                            (2) Divorce due to the fact that the marriage union
                            has been shaken from its foundation
                        </TitleBlock>
                        <TextBlock>
                            Each of the spouses can request a divorce with a
                            lawsuit if it is shaken to the extent that it is not
                            expected of them to continue their common life for
                            any reason. If there is no benefit worth protecting
                            for the defendant and the children in the
                            continuation of the marriage union, a divorce may be
                            decided.
                        </TextBlock>
                    </Block2>
                    <BlockImg2Mob></BlockImg2Mob>
                </WrapBlock>
                <WrapBlock>
                    <Block2 props="props">
                        <TitleBlock>
                            (3) Divorce due to the separation of the spouses
                        </TitleBlock>
                        <TextBlock>
                            If the parties have lived separately continuously
                            and for two years on the date of filing the divorce
                            case, the marriage union is deemed to have been
                            shaken from its foundation, and a divorce can be
                            decided upon the lawsuit filed by one of the spouses
                            for this reason, regardless of the fault status of
                            the parties.
                        </TextBlock>
                    </Block2>
                    <BlockImg3></BlockImg3>
                </WrapBlock>
            </WrapBlocks>
            <ContactForm />
        </Container>
    )
}

import styled from 'styled-components'
import { images } from '../../styles/images'
import { Wrap } from '../mainPage/MainPage.styles'

export const WrapImg = styled(Wrap)`
    background-image: url(${images.contact_bg});
`
export const WrapBlock = styled.div`
    display: flex;
    margin: 0 auto 100px;
    gap: 20px;
    @media (max-width: 575px) {
        flex-direction: column;
        gap: 20px;
        margin: 0 20px;
    }
`
export const BlockImg = styled.div`
    width: 45%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${images.contact});
    @media (max-width: 575px) {
        width: 100%;
        height: 400px;
        background-size: cover;
        margin-bottom: 50px;
    }
`

import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container } from '../mainPage/MainPage.styles'
import {
    WrapMainImg,
    SecondBackground,
    Title,
    Text,
    WrapSection,
    TextColor2,
} from '../partners/Partners.styles'
import {
    WrapBlocks,
    WrapBlock,
    Block2,
    TextBlock,
} from '../notaries/Notaries.styles'
import { FirstBackground } from '../divorce/Divorce.styles'
import {
    BlockImg1,
    BlockImg2,
    ThirdBackground1,
    BlockImg2Mob,
} from './PropertyDivision.styles'

export const PropertyDivision = () => {
    return (
        <Container>
            <WrapMainImg>
                <FirstBackground></FirstBackground>
                <SecondBackground>
                    <Title>Division of Property in Divorce</Title>
                    <Text props="props" style={{ textAlign: 'start' }}>
                        Article 26 of the Family Code regulates the division of
                        property.
                    </Text>
                    <Text>
                        Accordingly, in the matter of property division, there
                        are two divisions of property: property acquired within
                        the marriage union and personal property.
                    </Text>
                </SecondBackground>
                <ThirdBackground1></ThirdBackground1>
            </WrapMainImg>
            <WrapSection>
                <TextColor2>
                    While the movable and immovable properties acquired by the
                    spouses during the period of the marriage union are subject
                    to sharing, the properties defined by the law as personal
                    property are not subject to sharing.
                </TextColor2>
                <Text props="props">
                    The division of goods is made in such a way that at least
                    1/3 and at most 2/3 of the goods remain for one of the
                    parties.
                </Text>
            </WrapSection>
            <WrapBlocks>
                <WrapBlock>
                    <Block2 props="props">
                        <TextBlock>
                            Every case has a very important preparation process.
                            As CABINET D`AVOCATS MOHAMED & C, we make property
                            division, alimony, custody, and compensation
                            requests within the framework of the demands of the
                            clients in divorce cases, manage the process, and
                            defend against these claims against the client.
                        </TextBlock>
                    </Block2>
                    <BlockImg1></BlockImg1>
                </WrapBlock>
                <WrapBlock>
                    <BlockImg2></BlockImg2>
                    <Block2 props="props">
                        <TextBlock>
                            On the other hand, with our experience and knowledge
                            in the field of Family Law, we represent our clients
                            who have decided to divorce in the best way in the
                            divorce case filed.
                        </TextBlock>
                    </Block2>
                    <BlockImg2Mob></BlockImg2Mob>
                </WrapBlock>
            </WrapBlocks>
            <ContactForm />
        </Container>
    )
}

import { NavLink } from 'react-router-dom'
import { PATH } from '../../utils/routes'
import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container } from '../mainPage/MainPage.styles'
import { ButtonStart } from '../../components/navbar/Navbar.styles'
import {
    WrapNotaries,
    Title,
    TitleBlocks,
    WrapBlocks,
    WrapBlock,
    Block1,
    Block2,
    TitleBlock,
    TextBlock,
    WrapDocument,
    Text,
    WrapDownload,
    Document,
} from './Notaries.styles'
import { images } from '../../styles/images.js'

export const Notaries = () => {
    return (
        <Container>
            <WrapNotaries>
                <Title>
                    Our notary services are designed to provide our clients with
                    peace of mind, knowing that their legal affairs are in
                    capable hands.
                </Title>
                <NavLink to={PATH.CONTACT}>
                    <ButtonStart props="props">Contact Us</ButtonStart>
                </NavLink>
            </WrapNotaries>
            <TitleBlocks>Notary Services We Offer:</TitleBlocks>
            <WrapBlocks>
                <WrapBlock>
                    <Block1>
                        <TitleBlock>(1) Real Estate Transactions</TitleBlock>
                        <TextBlock>
                            Our notaries specialize in real estate transactions,
                            ensuring that property transfers are conducted
                            smoothly and in compliance with all legal
                            requirements.
                        </TextBlock>
                    </Block1>
                    <Block2>
                        <TitleBlock props="props">
                            (2) Estate Planning
                        </TitleBlock>
                        <TextBlock props="props">
                            We provide notary services for estate planning,
                            including the drafting of wills and powers of
                            attorney. Our goal is to help clients secure the
                            future of their assets and provide for their loved
                            ones.
                        </TextBlock>
                    </Block2>
                </WrapBlock>
                <WrapBlock>
                    <Block2>
                        <TitleBlock props="props">
                            (3) Contract Notarization
                        </TitleBlock>
                        <TextBlock props="props">
                            Cabinet d`Avocats Mohamed & C offers notary services
                            for the notarization of various contracts,
                            guaranteeing their legal validity and
                            enforceability.
                        </TextBlock>
                    </Block2>
                    <Block1>
                        <TitleBlock>(4) Legal Consultation</TitleBlock>
                        <TextBlock>
                            Clients can consult our notaries for expert advice
                            on legal matters. Whether it`s understanding the
                            implications of a document or seeking guidance on
                            legal processes, our notaries are here to assist.
                        </TextBlock>
                    </Block1>
                </WrapBlock>
            </WrapBlocks>
            <TitleBlocks>Our approved notaries:</TitleBlocks>
            <WrapBlocks>
                <WrapDocument>
                    <div>
                        <Title props="props">Nawab Ali</Title>
                        <Text>
                            Master of International Business Law, University of
                            Perpignan
                        </Text>
                    </div>
                    <a
                        download
                        href={require('./files/Representative letter NAWAB ALI.pdf')}
                    >
                        <WrapDownload
                            download
                            href={require('./files/Representative letter NAWAB ALI.pdf')}
                        >
                            <Text props="props">
                                Download representative letter
                            </Text>
                            <Document>
                                <img src={images.download} alt={''} />
                                <Text>Nawab_A.pdf</Text>
                            </Document>
                        </WrapDownload>
                    </a>
                </WrapDocument>
                <WrapDocument>
                    <div>
                        <Title props="props">Thilivhali Okello</Title>
                        <Text>Business Tax Lawyer</Text>
                    </div>
                    <a
                        download
                        href={require('./files/Representative letter THILIVHALI OKELLO.pdf')}
                    >
                        <WrapDownload>
                            <Text props="props">
                                Download representative letter
                            </Text>
                            <Document>
                                <img src={images.download} alt={''} />
                                <Text>Thilivhali_O.pdf</Text>
                            </Document>
                        </WrapDownload>
                    </a>
                </WrapDocument>
                <WrapDocument>
                    <div>
                        <Title props="props">Mohamed Boina</Title>
                        <Text>Attorney at Law - COMOROS BAR</Text>
                    </div>
                    <a
                        download
                        href={require('./files/Representative letter  MOHAMED BOINA.pdf')}
                    >
                        <WrapDownload>
                            <Text props="props">
                                Download representative letter
                            </Text>
                            <Document>
                                <img src={images.download} alt={''} />
                                <Text>Mohamed_B.pdf</Text>
                            </Document>
                        </WrapDownload>
                    </a>
                </WrapDocument>
            </WrapBlocks>
            <ContactForm />
        </Container>
    )
}

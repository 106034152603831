import styled from 'styled-components'

export const Wrap = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
`
export const Container = styled.div`
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
    padding: 40px;
    background: #ffffff;
    border-radius: 10px;
    border-top: 20px solid ${({ props }) => (props ? 'red' : '#000000')};
    border-bottom: 20px solid ${({ props }) => (props ? 'red' : '#000000')};
`

export const WrapPopup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`

export const PopupTitle = styled.div`
    text-align: center;
    font-family: 'CormorantBold';
    font-size: 32px;
    line-height: 32px;
    color: ${({ props }) => (props ? '#000000' : 'red')};
`

export const PopupSubTitle = styled.div`
    font-family: 'CormorantRegilar';
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 30px;
    color: rgba(172, 172, 172, 1);
    text-align: center;
`

import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { DropDown } from '../dropDown/DropDown.jsx'
import { PATH } from '../../utils/routes'
import { images } from '../../styles/images'
import {
    Wrap,
    Title,
    Logo,
    StyledWrapWithBurger,
    Hamburger,
    Menu,
    WrapWithBurger,
    TitleLogo,
    LogoWrap,
    WrapLinks,
} from './Navbar.styles'

export const Navbar = () => {
    const [showDropDown, setShowDropDown] = useState(true)
    const [openBurger, setOpenBurger] = useState(true)

    const changeOpenBurger = () => {
        setOpenBurger(!openBurger)
    }
    const toggleDropdown = () => {
        setShowDropDown(!showDropDown)
    }

    return (
        <StyledWrapWithBurger>
            <WrapWithBurger>
                <LogoWrap props="props">
                    <NavLink to={PATH.MAIN}>
                        <Logo src={images.logo} />
                    </NavLink>
                </LogoWrap>
                <Hamburger openBurger={openBurger} onClick={changeOpenBurger}>
                    <Menu></Menu>
                    <Menu></Menu>
                    <Menu></Menu>
                </Hamburger>
            </WrapWithBurger>
            <Wrap openBurger={openBurger}>
                <DropDown
                    changeOpenBurger={changeOpenBurger}
                    toggleDropdown={toggleDropdown}
                    showDropDown={showDropDown}
                />
                <WrapLinks showDropDown={!showDropDown}>
                    <Title props="props" onClick={changeOpenBurger}>
                        <NavLink to={PATH.NOTARIES}>Notaries & Service</NavLink>
                    </Title>
                    <TitleLogo props="props" onClick={changeOpenBurger}>
                        <NavLink to={PATH.MAIN}>
                            <Logo src={images.logo} />
                        </NavLink>
                    </TitleLogo>
                    <Title props="props" onClick={changeOpenBurger}>
                        <NavLink to={PATH.PARTNERS}>Our partners</NavLink>
                    </Title>
                    <Title props="props" onClick={changeOpenBurger}>
                        <NavLink to={PATH.CONTACT}>Contact Us</NavLink>
                    </Title>
                </WrapLinks>
            </Wrap>
        </StyledWrapWithBurger>
    )
}

import styled from 'styled-components'
import { images } from '../../styles/images'
import { ThirdBackground } from '../partners/Partners.styles'
import { Block1 } from '../notaries/Notaries.styles'

export const ThirdBackground1 = styled(ThirdBackground)`
    background-image: url(${images.criminal});
    @media (max-width: 575px) {
        height: 550px;
    }
`
export const BlockImg1 = styled(Block1)`
    background-size: cover;
    background-image: url(${images.criminal1});
    background-repeat: no-repeat;
    @media (max-width: 575px) {
        height: 340px;
    }
`
export const TextCriminal = styled.div`
    font-family: 'CormorantRegilar';
    font-size: ${({ props }) => (props ? '18px' : '24px')};
    line-height: ${({ props }) => (props ? '24px' : '30px')};
    color: rgba(45, 50, 56, 1);
    width: 50%;
    margin: 100px auto;
    text-align: center;
    @media (max-width: 575px) {
        width: 85%;
        margin: 40px;
    }
`
export const TextCr = styled.div`
    font-family: 'CormorantBold';
    font-size: 32px;
    line-height: 34px;
    color: #ffffff;
    text-align: center;
    @media (max-width: 575px) {
        font-family: 'CormorantRegilar';
        font-size: 24px;
        line-height: 24px;
    }
`
export const Line = styled.div`
    width: 280px;
    height: 2px;
    margin: 0 auto;
    background: rgba(172, 172, 172, 1);
`

import styled from 'styled-components'

export const DropDownWrapper = styled.div`
    text-align: center;
    position: relative;
`
export const Link = styled.div`
    padding-left: 40px;
    font-family: 'CormorantMedium';
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
    color: #000000;
    :hover {
        color: rgba(172, 172, 172, 1);
    }
`

export const DropDownContent = styled.div`
    display: ${({ showDropDown }) => (showDropDown ? 'none' : 'flex')};
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 88px;
    width: 100%;
    max-width: 1490px;
    background: #ffffff;
    border-top: 1px solid #000000;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 80px;
        border-top: none;
        transform: ${({ showDropdown }) =>
            showDropdown ? 'translateY(0px)' : 'translateY(50px)'};
        transition: transform 0.5s ease-in-out;
    }
`

import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { CustomModalSuccess } from '../customModal/CustomModalSuccess.jsx'
import { CustomModalError } from '../customModal/CustomModalError.jsx'
import { ButtonStart } from '../navbar/Navbar.styles'
import {
    WrapForm,
    TitleForm,
    Form,
    Input,
    WrapInputs,
    Textarea,
} from './contactForm.styles'

const validationSchema = yup.object({
    name: yup.string().required('Required field'),
    lastName: yup.string().required('Required field'),
    message: yup.string().required('Required field'),
    email: yup.string().email('invalid format').required('Required field'),
})

export const ContactForm = () => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const contactForm = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            email: '',
            message: '',
            subject: 'hello',
        },
        validationSchema,
        onSubmit: async (values, formikHelpers) => {
            let response = await fetch(
                'https://assembly.tehnik.tech/api/send_form/from',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify({
                        project: 'cabadvocats',
                        subject: values.subject,
                        data: {
                            name: values.name,
                            lastName: values.lastName,
                            email: values.email,
                            message: values.message,
                        },
                    }),
                }
            )

            let result = await response.json()

            if (result === 'ok') {
                setIsSuccessModalOpen(true)
                formikHelpers.resetForm()
            } else {
                setIsErrorModalOpen(true)
            }
        },
    })

    const closeSuccessModal = () => {
        setIsSuccessModalOpen(!isSuccessModalOpen)
    }

    const closeErrorModal = () => {
        setIsErrorModalOpen(!isErrorModalOpen)
    }

    return (
        <>
            <CustomModalSuccess
                closeSuccessModal={closeSuccessModal}
                isModalOpen={isSuccessModalOpen}
            ></CustomModalSuccess>
            <CustomModalError
                closeErrorModal={closeErrorModal}
                isModalOpen={isErrorModalOpen}
            ></CustomModalError>
            <WrapForm>
                <Form onSubmit={contactForm.handleSubmit}>
                    <TitleForm>
                        Feel free to contact us any time!
                        <br /> We’ll get back to you as soon as we can.
                    </TitleForm>
                    <WrapInputs>
                        <Input
                            type="text"
                            placeholder={'First name'}
                            id={'name'}
                            name={'name'}
                            value={contactForm.values.name}
                            onChange={contactForm.handleChange}
                        />
                        {contactForm.touched.name &&
                            contactForm.errors.name && (
                                <div style={{ color: 'red' }}>
                                    {contactForm.errors.name}
                                </div>
                            )}
                        <Input
                            type="text"
                            placeholder={'Last name'}
                            id={'lastName'}
                            name={'lastName'}
                            value={contactForm.values.lastName}
                            onChange={contactForm.handleChange}
                        />
                        {contactForm.touched.lastName &&
                            contactForm.errors.lastName && (
                                <div style={{ color: 'red' }}>
                                    {contactForm.errors.lastName}
                                </div>
                            )}
                    </WrapInputs>
                    <Input
                        type="email"
                        placeholder={'Email address'}
                        id={'email'}
                        name={'email'}
                        value={contactForm.values.email}
                        onChange={contactForm.handleChange}
                    />
                    {contactForm.touched.email && contactForm.errors.email && (
                        <div style={{ color: 'red' }}>
                            {contactForm.errors.email}
                        </div>
                    )}
                    <Textarea
                        type="message"
                        placeholder={'Message'}
                        id={'message'}
                        name={'message'}
                        value={contactForm.values.message}
                        onChange={contactForm.handleChange}
                    />
                    {contactForm.touched.message &&
                        contactForm.errors.message && (
                            <div style={{ color: 'red' }}>
                                {contactForm.errors.message}
                            </div>
                        )}
                    <ButtonStart type="submit">Contact Us</ButtonStart>
                </Form>
            </WrapForm>
        </>
    )
}

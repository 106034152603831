import styled from 'styled-components'

export const StyledWrapWithBurger = styled.div`
    @media (max-width: 1024px) {
        position: fixed;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        z-index: 200;
        background: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    @media (max-width: 1024px) {
        padding: 0px;
    }
`
export const Menu = styled.div``
export const Hamburger = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        margin-left: 30px;
        z-index: 300;
        cursor: pointer;
    }

    ${Menu} {
        width: 26px;
        height: 2px;
        margin: auto;
        margin-bottom: 5px;
        border-radius: 3px;
        background: #000000;
        transition: all 0.5s ease-in-out;

        &:nth-child(1) {
            margin-top: 5px;
            transform: ${({ openBurger }) =>
                openBurger
                    ? 'rotate(0)'
                    : 'rotate(45deg)  translate(10px, 10px)'};
        }

        &:nth-child(2) {
            opacity: 0;
            transform: ${({ openBurger }) =>
                openBurger ? 'translateX(0)' : 'translateX(100%)'};
            opacity: ${({ openBurger }) => (openBurger ? 1 : 0)};
        }

        &:nth-child(3) {
            transform: ${({ openBurger }) =>
                openBurger ? 'rotate(0)' : 'rotate(-45deg)'};
        }
    }
`
export const WrapWithBurger = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 50px;
    }
`
export const Wrap = styled.div`
    display: flex;
    align-items: center;
    position: fixed;
    max-width: 1440px;
    width: 100vw;
    z-index: 100;
    padding: 10px 0;
    background: #ffffff;
    @media (max-width: 1024px) {
        height: 100vh;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 100px;
        transform: ${({ openBurger }) =>
            openBurger ? 'translateX(100%)' : 'translateX(0)'};
        transition: transform 0.3s ease-in-out;
    }
    @media (max-width: 575px) {
    }
`
export const Logo = styled.img`
    @media (max-width: 1024px) {
        width: 150px;
    }
`

export const Title = styled.li`
    font-family: 'CormorantMedium';
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
    color: #000000;
    a {
        color: #000000;
        :hover {
            color: rgba(172, 172, 172, 1);
        }

        :active {
            color: rgba(45, 50, 56, 1);
            border-bottom: 1px solid black;
        }
    }
    :hover {
        color: rgba(172, 172, 172, 1);
    }
    @media (max-width: 1024px) {
        margin: 0;
        line-height: 34px;
    }
`
export const TitleLogo = styled(Title)`
    display: block;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const LogoWrap = styled(Title)`
    @media (max-width: 1024px) {
        position: relative;
        z-index: 300;
    }
`

export const WrapLinks = styled.div`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 40px;
        transform: ${({ showDropDown }) => {
            return showDropDown ? 'translateY(200%)' : 'translateY(0%)'
        }};
        transition: transform 0.5s ease-in-out;
    }
`

export const ButtonStart = styled.button`
    display: block;
    width: 200px;
    height: 60px;
    font-family: 'CormorantMedium';
    font-size: 24px;
    line-height: 29px;
    background: transparent;
    border-radius: 8px;
    border: 2px solid ${({ props }) => (props ? '#ffffff' : '#000000')};
    color: ${({ props }) => (props ? '#ffffff' : '#000000')};
    cursor: pointer;
    :hover {
        background: #ffffff;
        color: ${({ props }) => (props ? '#000000' : '#ffffff')};
    }
    @media (max-width: 1024px) {
    }
`

import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container } from '../mainPage/MainPage.styles'
import {
    WrapMainImg,
    SecondBackground,
    Title,
    Text,
    WrapSection,
    TextColor,
    TextColor2,
} from '../partners/Partners.styles'
import { FirstBackground } from '../divorce/Divorce.styles'
import { ThirdBackground1 } from './RealEstate.styles'

export const RealEstate = () => {
    return (
        <Container>
            <WrapMainImg>
                <FirstBackground></FirstBackground>
                <SecondBackground>
                    <Title>Real Estate and Construction Law in Comoros</Title>
                    <Text props="props" style={{ textAlign: 'start' }}>
                        Real estate law is a very comprehensive branch of law
                        with many sub-branches.
                    </Text>
                    <Text>
                        At the same time, since it is one of the branches of law
                        with the highest number of exceptions, special expertise
                        is required for disputes in the field of real estate
                        law.
                    </Text>
                </SecondBackground>
                <ThirdBackground1></ThirdBackground1>
            </WrapMainImg>
            <WrapSection>
                <TextColor>
                    In these cases, which require special expertise and are
                    difficult types of cases,{' '}
                    <TextColor2>
                        CABINET D`AVOCATS MOHAMED & C provides support in the
                        entire legal process as Comoros Real Estate and
                        Construction Law.
                    </TextColor2>
                </TextColor>
            </WrapSection>
            <ContactForm />
        </Container>
    )
}

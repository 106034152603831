import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container } from '../mainPage/MainPage.styles'
import {
    WrapMainImg,
    SecondBackground,
    Title,
    Text,
    WrapSection,
    TextColor,
    TextColor2,
} from '../partners/Partners.styles'
import {
    WrapBlocks,
    WrapBlock,
    Block2,
    TitleBlock,
    TextBlock,
    TitleBlocks,
} from '../notaries/Notaries.styles'
import {
    FirstBackground,
    ThirdBackground1,
    BlockImg1,
    BlockImg2,
    BlockImg3,
    BlockImg4,
    BlockImg2Mob,
    BlockImg4Mob,
} from './NotaryServices.styles'

export const NotaryServices = () => {
    return (
        <Container>
            <WrapMainImg>
                <FirstBackground></FirstBackground>
                <SecondBackground>
                    <Title>Notary Services</Title>
                    <Text>
                        At Cabinet d`Avocats Mohamed & C, we understand the
                        critical role that notary services play in ensuring the
                        legality and authenticity of various documents.
                    </Text>
                </SecondBackground>
                <ThirdBackground1></ThirdBackground1>
            </WrapMainImg>
            <WrapSection>
                <TextColor>
                    Our dedicated team of notaries is committed to{' '}
                    <TextColor2>
                        providing comprehensive services that adhere to the
                        highest standards
                    </TextColor2>
                    of professionalism and legal expertise.
                </TextColor>
            </WrapSection>
            <TitleBlocks>Our services:</TitleBlocks>
            <WrapBlocks>
                <WrapBlock>
                    <Block2 props="props">
                        <TitleBlock>
                            (1) Verification and Authentication
                        </TitleBlock>
                        <TextBlock>
                            Our notaries are tasked with the responsibility of
                            verifying the authenticity of documents. This
                            includes scrutinizing the identity of the parties
                            involved and ensuring that the information presented
                            is accurate.
                        </TextBlock>
                    </Block2>
                    <BlockImg1></BlockImg1>
                </WrapBlock>
                <WrapBlock>
                    <BlockImg2></BlockImg2>
                    <Block2 props="props">
                        <TitleBlock>(2) Legal Advice</TitleBlock>
                        <TextBlock>
                            Beyond authentication, our notaries offer valuable
                            legal advice related to the documents they handle.
                            Whether it`s a real estate transaction, wills,
                            contracts, or other legal instruments, our notaries
                            provide guidance to ensure that our clients make
                            informed decisions.
                        </TextBlock>
                    </Block2>
                    <BlockImg2Mob></BlockImg2Mob>
                </WrapBlock>
                <WrapBlock>
                    <Block2 props="props">
                        <TitleBlock>(3) Drafting and Preparation</TitleBlock>
                        <TextBlock>
                            Notaries at Cabinet d`Avocats Mohamed & C can assist
                            in the drafting and preparation of various legal
                            documents. This includes contracts, affidavits, and
                            other agreements, ensuring that they comply with
                            applicable laws and regulations.
                        </TextBlock>
                    </Block2>
                    <BlockImg3></BlockImg3>
                </WrapBlock>
                <WrapBlock>
                    <BlockImg4></BlockImg4>
                    <Block2 props="props">
                        <TitleBlock>(4) Record Keeping</TitleBlock>
                        <TextBlock>
                            Our notaries maintain meticulous records of all
                            transactions they oversee. This not only serves as a
                            reference for the parties involved but also
                            contributes to the transparency and integrity of the
                            legal process.
                        </TextBlock>
                    </Block2>
                    <BlockImg4Mob></BlockImg4Mob>
                </WrapBlock>
            </WrapBlocks>
            <ContactForm />
        </Container>
    )
}

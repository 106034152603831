import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container, Title } from '../mainPage/MainPage.styles'
import {
    TitleBlocks,
    TitleBlock,
    Block1,
    TextBlock,
} from '../notaries/Notaries.styles'
import { WrapImg, BlockImg, WrapBlock } from './Contacts.styles'

export const Contacts = () => {
    return (
        <Container>
            <WrapImg>
                <Title>
                    Contact <br /> Information
                </Title>
            </WrapImg>
            <TitleBlocks>CABINET D`AVOCATS MOHAMED & C</TitleBlocks>
            <WrapBlock>
                <Block1>
                    <TitleBlock>Address:</TitleBlock>
                    <TextBlock>
                        Boulevard Karthala, Darsanda, Bâtiment Ridjali, 1er
                        étage, Fomboni, Mwali, Comores
                    </TextBlock>
                    <TitleBlock>Email:</TitleBlock>
                    <TextBlock>support@cabadvocats.com</TextBlock>
                </Block1>
                <BlockImg></BlockImg>
            </WrapBlock>
            <ContactForm />
        </Container>
    )
}

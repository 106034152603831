import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { Container } from '../mainPage/MainPage.styles'
import {
    WrapMainImg,
    SecondBackground,
    Title,
    Text,
    WrapSection,
    TextColor,
    TextColor2,
} from '../partners/Partners.styles'
import {
    WrapBlocks,
    WrapBlock,
    Block2,
    TextBlock,
} from '../notaries/Notaries.styles'
import { FirstBackground } from '../divorce/Divorce.styles'
import { ThirdBackground1, BlockImg1 } from './Estate.styles'

export const Estate = () => {
    return (
        <Container>
            <WrapMainImg>
                <FirstBackground></FirstBackground>
                <SecondBackground>
                    <Title>Real Estate</Title>
                    <Text props="props" style={{ textAlign: 'start' }}>
                        Real Estate law is a branch of law that determines the
                        rights on immovables such as houses, apartments, land,
                        fields, as well as finding the solution of disputes in
                        the same area. Real estate law is included in property
                        law, which is a part of civil law.
                    </Text>
                    <Text>
                        If you are looking for Comoros Real Estate Law, Comoros
                        Construction Law, or Comoros Real Estate & Construction
                        Lawyer, the best answer is CABINET D`AVOCATS MOHAMED &
                        C.
                    </Text>
                </SecondBackground>
                <ThirdBackground1></ThirdBackground1>
            </WrapMainImg>
            <WrapSection>
                <Text props="props">
                    This branch of law is closely related to almost every
                    segment of society.
                </Text>
                <TextColor>
                    <TextColor2>
                        Issues such as title deed and registration cases,
                        appropriation cases, expropriation of immovable
                        properties, including issues that seriously affect a
                        large segment of the society, such as the determination
                        and registration of rental prices, are included in real
                        estate law.
                    </TextColor2>
                    Litigation resolves disputes that may arise in these areas.
                </TextColor>
            </WrapSection>
            <WrapBlocks>
                <WrapBlock>
                    <Block2 props="props">
                        <TextBlock>
                            However, this branch of law is considered to be a
                            very difficult branch of law. Because sometimes even
                            in a single case, there are issues that require
                            expertise in different branches of law. For this
                            reason, real estate law is a field that requires
                            special expertise. In this area where exceptional
                            situations are intense, it is not possible to solve
                            cases without a lawyer who is an expert in his
                            field.
                        </TextBlock>
                    </Block2>
                    <BlockImg1></BlockImg1>
                </WrapBlock>
            </WrapBlocks>
            <ContactForm />
        </Container>
    )
}

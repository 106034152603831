export const PATH = {
    MAIN: '/',
    NOTARIES: '/notaries',
    PARTNERS: '/partners',
    CONTACT: '/contact',
    DIVORCE: '/divorce',
    DIVISIONPROPERTY: '/division-of-property',
    CONSTRUCTIONLAW: '/construction-law',
    REALESTATE: '/real-estate',
    CRIMINAL: '/criminal',
    NOTARYSERVICES: '/notary-services',
}

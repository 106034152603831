import styled from 'styled-components'

export const WrapForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(247, 240, 234, 1);
`
export const TitleForm = styled.div`
    margin-bottom: 50px;
    text-align: center;
    font-family: 'CormorantMedium';
    font-size: 24px;
    line-height: 30px;
    color: #000000;
`
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 50%;
    padding: 100px 0;
    @media (max-width: 768px) {
        width: 85%;
    }
`
export const WrapInputs = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    @media (max-width: 575px) {
        flex-direction: column;
    }
`
export const Input = styled.input`
    width: 100%;
    border: none;
    height: 56px;
    border-radius: 10px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    font-family: 'CormorantMedium';
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    :focus {
        outline: 1.5px solid #979797;
    }
    :hover {
        outline: 1.5px solid #979797;
    }
`
export const Textarea = styled.textarea`
    width: 100%;
    border: none;
    height: auto;
    min-height: 230px;
    margin-bottom: 50px;
    border-radius: 10px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    font-family: 'CormorantMedium';
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    :focus {
        outline: 1.5px solid #979797;
    }
    :hover {
        outline: 1.5px solid #979797;
    }
`

import styled from 'styled-components'
import { images } from '../../styles/images'
import { ThirdBackground } from '../partners/Partners.styles'
import { Block1 } from '../notaries/Notaries.styles'

export const ThirdBackground1 = styled(ThirdBackground)`
    background-image: url(${images.estate});
    @media (max-width: 575px) {
        height: 550px;
    }
`
export const BlockImg1 = styled(Block1)`
    background-size: cover;
    background-image: url(${images.estate1});
    background-repeat: no-repeat;
    @media (max-width: 575px) {
        height: 340px;
    }
`

import styled from 'styled-components'
import { images } from '../../styles/images'

export const WrapMainImg = styled.div`
    display: flex;
    justify-content: space-between;
    height: 858px;
    gap: 20px;
    margin-top: 90px;
    @media (max-width: 575px) {
        flex-direction: column;
        height: auto;
        gap: 0px;
    }
`
export const FirstBackground = styled.div`
    display: block;
    width: 100%;
    background-size: cover;
    background-image: url(${images.fog});
    background-repeat: no-repeat;
    @media (max-width: 575px) {
        display: none;
    }
`
export const SecondBackground = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: ${({ props }) => (props ? '100px 200px' : '0 30px')};
    gap: 50px;
    background: rgba(45, 50, 56, 1);
    @media (max-width: 575px) {
        height: ${({ props }) => (props ? 'auto' : '630px')};
        padding: 80px 30px;
        gap: 30px;
    }
`
export const ThirdBackground = styled.div`
    width: 100%;
    background-size: cover;
    background-image: url(${images.partner});
    background-repeat: no-repeat;
    @media (max-width: 575px) {
        height: 550px;
    }
`
export const Title = styled.div`
    font-family: 'CormorantBold';
    font-size: 56px;
    line-height: 56px;
    color: rgba(247, 240, 234, 1);
    @media (max-width: 768px) {
        font-size: 48px;
        line-height: 48px;
    }
`
export const Text = styled.div`
    font-family: 'CormorantRegilar';
    font-size: ${({ props }) => (props ? '18px' : '24px')};
    line-height: ${({ props }) => (props ? '24px' : '30px')};
    color: ${({ props }) =>
        props ? 'rgba(172, 172, 172, 1)' : 'rgba(247, 240, 234, 1)'};
    text-align: ${({ props }) => (props ? 'center' : 'start')};
    width: ${({ props }) => (props ? '70%' : 'auto')};
    @media (max-width: 575px) {
        font-size: ${({ props }) => (props ? '18px' : '20px')};
        line-height: ${({ props }) => (props ? '24px' : '20px')};
    }
`
export const WrapSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
    margin: 100px auto;
    gap: 30px;
    @media (max-width: 768px) {
        width: 85%;
        margin: 30px;
        gap: 30px;
    }
`
export const TextColor = styled.div`
    font-family: 'CormorantBold';
    font-size: 40px;
    line-height: 40px;
    color: rgba(172, 172, 172, 1);
    text-align: center;
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 28px;
    }
`
export const TextColor2 = styled.span`
    font-family: 'CormorantBold';
    font-size: 40px;
    line-height: 40px;
    color: rgba(45, 50, 56, 1);
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 28px;
    }
`
export const Line = styled.div`
    width: 200px;
    height: 1px;
    margin-top: 50px;
    background: rgba(224, 224, 224, 1);
`
export const ImgBtn = styled.img`
    display: block;
    width: 65%;
    margin: 0px auto 100px;
    @media (max-width: 575px) {
        display: none;
    }
`
export const ImgBtnMobile = styled.img`
    display: none;
    @media (max-width: 575px) {
        display: block;
        width: 85%;
        margin: 30px;
    }
`

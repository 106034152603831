import { motion } from 'framer-motion'
import { ButtonStart } from '../navbar/Navbar.styles'
import {
    WrapPopup,
    PopupTitle,
    PopupSubTitle,
    Wrap,
    Container,
} from './customModal.styles'

export const CustomModalError = ({ isModalOpen, closeErrorModal }) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            {isModalOpen && (
                <Wrap>
                    <Container props="props">
                        <WrapPopup>
                            <PopupTitle>Oops!</PopupTitle>
                            <PopupSubTitle>
                                Something went wrong. Please try again later.
                            </PopupSubTitle>
                            <ButtonStart onClick={() => closeErrorModal()}>
                                Okay
                            </ButtonStart>
                        </WrapPopup>
                    </Container>
                </Wrap>
            )}
        </motion.div>
    )
}

import styled from 'styled-components'
import { images } from '../../styles/images'
import { Wrap } from '../mainPage/MainPage.styles'

export const WrapNotaries = styled(Wrap)`
    background-image: url(${images.notaries});
    @media (max-width: 575px) {
        background-position: left;
    }
`
export const Title = styled.h4`
    max-width: ${({ props }) => (props ? '100%' : '50%')};
    margin: 0;
    font-family: 'CormorantBold';
    font-size: 40px;
    line-height: 40px;
    color: ${({ props }) => (props ? '#000000' : '#ffffff')};
    text-align: ${({ props }) => (props ? 'start' : 'center')};
    @media (max-width: 1024px) {
        max-width: 700px;
        font-size: 50px;
        line-height: 50px;
    }
    @media (max-width: 575px) {
        max-width: 350px;
        font-size: 32px;
        line-height: 32px;
    }
`
export const TitleBlocks = styled.div`
    margin: 80px auto;
    font-family: 'CormorantBold';
    font-size: 56px;
    line-height: 50px;
    color: rgba(45, 50, 56, 1);
    @media (max-width: 575px) {
        width: 300px;
        margin: 20px auto;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
    }
`
export const WrapBlocks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 100px 100px;
    gap: 20px;
    @media (max-width: 768px) {
        margin: 20px;
    }
`
export const WrapBlock = styled.div`
    display: flex;
    gap: 20px;
    @media (max-width: 575px) {
        flex-direction: column;
        margin: 0px;
    }
`
export const Block1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 80px 50px 80px 30px;
    gap: 20px;
    background: rgba(45, 50, 56, 1);
    @media (max-width: 575px) {
        width: 100%;
        padding: 20px;
    }
`
export const Block2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding: 80px 30px;
    gap: 20px;
    background: ${({ props }) =>
        props ? 'rgba(172, 172, 172, 1)' : 'rgba(224, 224, 224, 1)'};
    @media (max-width: 575px) {
        width: 100%;
        padding: 20px;
    }
`
export const TitleBlock = styled.div`
    font-family: 'CormorantBold';
    font-size: 32px;
    line-height: 32px;
    color: ${({ props }) => (props ? '#000000' : '#ffffff')};
`
export const TextBlock = styled.div`
    font-family: 'CormorantRegilar';
    font-size: 24px;
    line-height: 30px;
    color: ${({ props }) => (props ? '#000000' : '#ffffff')};
`
export const WrapDocument = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    gap: 20px;
    background: rgba(239, 239, 239, 1);
    @media (max-width: 575px) {
        flex-direction: column;
        gap: 50px;
    }
`
export const Text = styled.div`
    max-width: 65%;
    margin-top: 15px;
    font-family: 'CormorantRegilar';
    font-size: 18px;
    line-height: 21px;
    color: ${({ props }) =>
        props ? 'rgba(172, 172, 172, 1)' : 'rgba(45, 50, 56, 1)'};
    @media (max-width: 575px) {
        max-width: 80%;
    }
`
export const WrapDownload = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    border-left: 1.5px solid rgba(224, 224, 224, 1);
    @media (max-width: 575px) {
        max-width: 65%;
        border-top: 1.5px solid rgba(224, 224, 224, 1);
        border-left: none;
        padding-left: 0px;
    }
`
export const Document = styled.div`
    display: flex;
    align-items: flex-end;
`

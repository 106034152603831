import { ContactForm } from '../../components/contactForm/contactForm.jsx'
import { images } from '../../styles/images.js'
import { Container } from '../mainPage/MainPage.styles'
import {
    WrapMainImg,
    FirstBackground,
    SecondBackground,
    ThirdBackground,
    Title,
    Text,
    WrapSection,
    TextColor,
    TextColor2,
    ImgBtn,
    Line,
    ImgBtnMobile,
} from './Partners.styles'

export const Partners = () => {
    return (
        <Container>
            <WrapMainImg>
                <FirstBackground></FirstBackground>
                <SecondBackground>
                    <Title>Our partners</Title>
                    <Text>
                        For the past several years we have been cooperating with
                        different companies and organisations
                    </Text>
                </SecondBackground>
                <ThirdBackground></ThirdBackground>
            </WrapMainImg>
            <WrapSection>
                <TextColor>
                    One of the most known of our partners is{' '}
                    <TextColor2>
                        Mwali International Services Authority (M.I.S.A.)
                    </TextColor2>
                    , which specializes in providing different financial
                    services in the jurisdiction of Mwali, Comoros.
                </TextColor>
                <Text props="props">
                    Our company has created strong business relationships with
                    that organisation and for several years we tightly cooperate
                    together seeking to provide a qualified legal support to
                    their clients.
                </Text>
                <Line></Line>
            </WrapSection>
            <ImgBtn src={images.hugeBtn} alt={''} />
            <ImgBtnMobile src={images.hugeBtnMobile} alt={''} />
            <ContactForm />
        </Container>
    )
}

import styled from 'styled-components'
import { images } from '../../styles/images'
import { Block1 } from '../notaries/Notaries.styles'
import { ThirdBackground } from '../partners/Partners.styles'

export const ThirdBackground1 = styled(ThirdBackground)`
    background-image: url(${images.division_bg});
    @media (max-width: 575px) {
        height: 550px;
    }
`
export const BlockImg1 = styled(Block1)`
    background-size: cover;
    background-image: url(${images.division1});
    background-repeat: no-repeat;
    @media (max-width: 575px) {
        height: 340px;
    }
`
export const BlockImg2 = styled(BlockImg1)`
    background-image: url(${images.division2});
    display: block;
    @media (max-width: 575px) {
        display: none;
    }
`
export const BlockImg2Mob = styled(BlockImg1)`
    display: none;
    @media (max-width: 575px) {
        display: block;
        background-position: right;
        background-image: url(${images.division2});
    }
`

import styled from 'styled-components'

export const Wrap = styled.div`
    padding: 150px 70px;
    background: rgba(45, 50, 56, 1);
    @media (max-width: 1024px) {
        padding: 50px 30px;
    }
`
export const Logo = styled.img`
    display: block;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const Logo2 = styled.img`
    display: none;
    @media (max-width: 1024px) {
        display: block;
        width: 250px;
        margin-bottom: 40px;
    }
`
export const WrapLinks = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 0;
    border-bottom: 2px solid rgba(63, 63, 63, 1);
    a {
        font-size: 24px;
        text-decoration: none;
        color: rgba(247, 240, 234, 1);
        &.active {
            color: rgba(247, 240, 234, 1);
            border-bottom: none;
        }
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        a {
            font-size: 24px;
            color: rgba(247, 240, 234, 1);
            &.active {
                color: rgba(247, 240, 234, 1);
                border-bottom: none;
            }
        }
    }
`
export const WrapLinks2 = styled(WrapLinks)`
    a {
        font-size: 18px;
        text-decoration: none;
        color: rgba(247, 240, 234, 1);
        &.active {
            color: rgba(247, 240, 234, 1);
            border-bottom: none;
        }
    }
    @media (max-width: 1024px) {
        a {
            font-size: 18px;
        }
    }
`
export const WrapRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding-top: 40px;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 0px;
        padding-top: 20px;
    }
`
export const CopyRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 385px;
    gap: 7px;
`
export const TextCopyRight = styled.div`
    font-family: 'CormorantMedium';
    font-size: 18px;
    text-align: center;
    color: rgba(247, 240, 234, 1);
`

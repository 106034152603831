import { Routes, Route } from 'react-router-dom'
import { MainPage } from './pages/mainPage/MainPage.jsx'
import { Navbar } from './components/navbar/Navbar.jsx'
import { Footer } from './components/footer/Footer.jsx'
import { Notaries } from './pages/notaries/Notaries.jsx'
import { Partners } from './pages/partners/Partners.jsx'
import { Contacts } from './pages/contacts/Contacts.jsx'
import { Divorce } from './pages/divorce/Divorce.jsx'
import { PropertyDivision } from './pages/propertyDivision/PropertyDivision.jsx'
import { RealEstate } from './pages/realEstate/RealEstate.jsx'
import { Estate } from './pages/estate/Estate.jsx'
import { Criminal } from './pages/criminal/Criminal.jsx'
import { NotaryServices } from './pages/notaryServices/NotaryServices.jsx'

export const App = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/notaries" element={<Notaries />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/contact" element={<Contacts />} />
                <Route path="/divorce" element={<Divorce />} />
                <Route
                    path="/division-of-property"
                    element={<PropertyDivision />}
                />
                <Route path="/construction-law" element={<RealEstate />} />
                <Route path="/real-estate" element={<Estate />} />
                <Route path="/criminal" element={<Criminal />} />
                <Route path="/notary-services" element={<NotaryServices />} />
            </Routes>
            <Footer />
        </>
    )
}
